<script lang="ts" setup>
import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { ElMessageBox } from 'element-plus'
import { cloneDeep, repeat } from 'lodash'
import { useForm } from 'vee-validate'
import { ref, UnwrapNestedRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { bool, number, object, string } from 'yup'
import { vMaska } from 'maska/vue'
import { MaskaDetail } from 'maska'
import { integerMask, moneyMask } from '@/07-Shared/lib/maskHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
  helpDescriber: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}
const emit = defineEmits<IEmits>()

const schema = object({
  sum: number().required('Это поле обязательно для заполнения'),
  name: string().required('Это поле обязательно для заполнения'),
  other_attributes: object({
    date: string().when('iis_type', ([iis_type], schema) => {
      return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
    }),
    inn: number()
      .len([10, 12])
      .when('iis_type', ([iis_type], schema) => {
        return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
      }),
    kpp: number()
      .len(9)
      .when('iis_type', ([iis_type], schema) => {
        return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
      }),
    contract_id: string().when('iis_type', ([iis_type], schema) => {
      return iis_type === 'A' ? schema.required('Поле является обязательным') : schema
    }),
    simplified: bool(),
    iis_type: string().oneOf(['A', 'B']).default('A'),
  }),
})
const validationSchema = toTypedSchema(schema)
const { setValues, values, validate, errorBag } = useForm({
  validateOnMount: true,
  validationSchema,
  initialValues: Object.assign({}, { ...props.item.item }),
})

async function prepareItem() {
  if (values.other_attributes?.iis_type === 'B') {
    setValues({
      sum: 100,
      name: 'name',
    })
  }
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

const iisBroker = ref(false)
function checkIisSum() {
  if (values.sum && values.sum > 400000) {
    ElMessageBox({
      title: 'Вычет по ИИС',
      message: 'Получить вычет по ИИС можно только с суммы не более чем 400 000 ₽',
      type: 'success',
      confirmButtonText: 'Понятно',
    }).then(() => {
      setValues({
        sum: 400000,
      })
    })
  }
}
watch(iisBroker, (val) => {
  if (val) {
    ElMessageBox.confirm('При получении вычета через брокера его нет необходимости указывать в декларации', {
      confirmButtonText: 'Удалить вычет',
      cancelButtonText: 'Не удалять',
    })
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        iisBroker.value = false
      })
  }
})
const route = useRoute()
const router = useRouter()
function redirect() {
  const { id, good_id } = route.params
  router.push({
    name: 'add_incomes',
    params: { id, good_id },
  })
}
function unmasked(value: CustomEvent<MaskaDetail>) {
  console.log(value.detail)
}
</script>

<template>
  <div class="px-4 pt-4 mb-4">
    <div class="mb-4">
      <form-item name="other_attributes.iis_type" v-slot="{ field, value }">
        <el-radio-group :model-value="value" v-bind="field">
          <div class="flex items-center">
            <div class="flex items-center mb-1 mr-1">
              <el-radio value="A">Вычет тип А</el-radio>
              <question-popover color="#021c1a4d">
                <div style="word-break: break-word" v-html="helpDescriber?.a_type"></div>
              </question-popover>
            </div>
            <div class="flex items-center mb-1">
              <el-radio value="B">Вычет тип Б</el-radio>
              <question-popover color="#021c1a4d">
                <div style="word-break: break-word" v-html="helpDescriber?.b_type"></div>
              </question-popover>
            </div>
          </div>
        </el-radio-group>
      </form-item>
    </div>
    <div v-if="values.other_attributes?.iis_type === 'A'">
      <div class="mb-8">
        <h3 class="mb-4">Данные брокера</h3>
        <div class="flex flex-wrap items-center">
          <div>
            <p class="font-semibold mb-1">Название брокера, где открыт ИИС</p>
            <form-item name="name" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div class="px-4">
            <p class="font-semibold mb-1">ИНН брокера</p>
            <form-item name="other_attributes.inn" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="123456789012" v-maska="integerMask" />
            </form-item>
          </div>
          <div>
            <p class="font-semibold mb-1">КПП брокера</p>
            <form-item name="other_attributes.kpp" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" placeholder="123456789" v-maska="integerMask" />
            </form-item>
          </div>
        </div>
      </div>
      <h3 class="mb-4">Данные договора</h3>
      <div class="flex flex-wrap items-center mb-4">
        <div>
          <p class="font-semibold mb-1">Номер договора</p>
          <form-item name="other_attributes.contract_id" v-slot="{ field, value }">
            <el-input :model-value="value" v-bind="field" />
          </form-item>
        </div>
        <div class="px-4">
          <p class="font-semibold mb-1">Дата заключения договора</p>
          <form-item name="other_attributes.date" v-slot="{ field, value }">
            <el-date-picker
              :model-value="value"
              v-bind="field"
              type="date"
              placeholder="ДД.ММ.ГГГГ"
              format="DD.MM.YYYY"
              value-format="DD.MM.YYYY"
            />
          </form-item>
        </div>
        <div>
          <p class="font-semibold mb-1">Сумма внесенная на ИИС в течение года</p>
          <form-item name="sum" v-slot="{ field, value }">
            <el-input
              :model-value="value"
              v-bind="field"
              placeholder="не более 400 000 руб."
              @focusout="checkIisSum"
              v-maska="moneyMask"
            />
          </form-item>
        </div>
      </div>
      <div>
        <form-item name="other_attributes.simplified" v-slot="{ field, value }">
          <Checkbox :value="value" v-bind="field">
            Вычет был предоставлен в упрощенном порядке
            <question-popover color="#021c1a4d">
              <div style="word-break: break-word" v-html="helpDescriber?.simplified"></div>
            </question-popover>
          </Checkbox>
        </form-item>
      </div>
    </div>
    <div v-else>
      <div class="mb-4">
        <h4 class="mb-4">Вычет был получен через брокера?</h4>
        <div class="flex items-center">
          <div class="margin-h--right-20">
            <el-radio v-model="iisBroker" :label="false">Нет</el-radio>
          </div>
          <div>
            <el-radio v-model="iisBroker" :label="true">Да</el-radio>
          </div>
        </div>
      </div>
      <div v-if="!iisBroker">
        <p class="mb-4">Чтобы получить вычет, необходимо указать доходы.</p>
        <p class="mb-8">
          Для этого загрузите справку о доходах (2-НДФЛ), полученных в рамках данного ИИС, выбрав на странице Доп.
          доходов ситуацию "Справки о доходах (ранее 2-НДФЛ)".
        </p>
        <el-button @click="redirect">Загрузить справки 2-НДФЛ</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
.el-radio
  margin-right: 0
</style>
