import { useRequestService } from '@/use/useRequestService'
import { usePDStore } from '@/entityes/invoice/personal_data/pd.store'
import { IPersonalData, IPersonalDatas, ISuggestions } from '@/entityes/invoice/personal_data/pd.type'
import { ofetch } from 'ofetch'
import { ref } from 'vue'

export function usePDRepository() {
  const { fetch } = useRequestService()
  const pdStore = usePDStore()

  function createPersonalData(id: number | string, body: IPersonalData) {
    return fetch<IPersonalData>(`/invoices/${id}/personal_data`, {
      method: 'post',
      body,
    })
  }
  function getPersonalData(id: string | number): Promise<IPersonalDatas> {
    getPersonalData.indicator.value = true
    return new Promise((resolve, reject) => {
      fetch<IPersonalDatas>(`/invoices/${id}/personal_data`)
        .then((res) => {
          if (res) pdStore.pds = res
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => {
          getPersonalData.indicator.value = false
        })
    })
  }
  getPersonalData.indicator = ref(false)
  function getCurrentPersonalDataCard(id: string | number): Promise<IPersonalData> {
    pdStore.currentPD = undefined
    return new Promise((resolve, reject) => {
      fetch<IPersonalData>(`/invoices/${id}/current_personal_data`)
        .then((res) => {
          if (res) pdStore.currentPD = res
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }
  function getOKTMOIFNS(val: string) {
    return new Promise((resolve, reject) => {
      fetch<ISuggestions>(`/get_oktmo_ifns?user_input=${val}`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function removePersonalDataCard(id: string | number, cardId: string | number) {
    return fetch(`/invoices/${id}/personal_data/${cardId}`, {
      method: 'delete',
    })
  }

  return {
    getPersonalData,
    getCurrentPersonalDataCard,
    getOKTMOIFNS,
    createPersonalData,
    removePersonalDataCard,
  }
}
