<script setup lang="ts">
import { computed, ref } from 'vue'
import useFileDownload, { IDownloadFileConfig } from '@/use/useFileDownload'
import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AdminRenderFiles from '@/06-Entities/AdminInvoiceRemote/ui/AdminRenderFiles.vue'
import { useFileAdminRepository } from '@/entityes/invoice/file/file.admin.repository'

const fileStore = useFileStore()
const invoiceStore = useInvoiceStore()
const { downloadFileRequest } = useFileDownload()
const { getAllFilesInInvoice } = useFileAdminRepository()
const invoiceId = computed(() => invoiceStore.invoice && invoiceStore.invoice?.id)
const TBankChatFiles = computed(
  () => fileStore.filesAdmin && fileStore.filesAdmin.files.filter((file) => file.tag === 'tinkoff_chat_files')
)
const tp = ref(1)
const cp = ref(1)
const getFile = (config: IDownloadFileConfig, file: IDownloadFileConfig) => {
  file.isLoading = true
  downloadFileRequest({
    invoiceId: config.invoiceId,
    fileType: config.fileType,
    fileId: config.fileId,
    name: config.name,
  })?.finally(() => {
    file.isLoading = false
  })
}
function changePage(page: number) {
  if (!!invoiceStore.invoice) {
    return getAllFilesInInvoice(invoiceStore.invoice.id, page)
  }
}

function setPagination() {
  tp.value = fileStore.filesAdmin?.total_pages || 1
  cp.value = fileStore.filesAdmin?.current_page || 1
}

setPagination()
</script>

<template>
  <div v-if="!!fileStore.filesAdmin && !!invoiceStore.invoice">
    <el-pagination
      class="mb-2"
      :hide-on-single-page="tp === 1"
      :current-page="cp"
      :total="tp"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      background
    />
    <el-scrollbar max-height="400">
      <div class="flex items-center flex-wrap">
        <div
          v-for="file in TBankChatFiles"
          :key="file.id"
          @click="
            getFile({ invoiceId: invoiceStore.invoice.id, fileType: file.tag, fileId: file.id, name: file.name }, file)
          "
          class="mb-2 mr-2"
        >
          <admin-render-files :file="file" />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<style scoped lang="sass"></style>
