<script setup lang="ts">
import { ref, watch, toRef } from 'vue'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useRequestService } from '@/use/useRequestService'
import { ElNotification } from 'element-plus'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { useScroll } from '@vueuse/core'

interface IMessage {
  author_type: 'Client' | 'Manager'
  created_at: string
  external_id: string
  id: number
  text: string
}

const { fetch } = useRequestService()
const invoiceStore = useInvoiceStore()
const showSidebar = ref<boolean>(false)
const loading = ref<boolean>(false)
const scrollBar = ref<HTMLElement | null>(null)
const { y } = useScroll(scrollBar, { behavior: 'smooth' })

const schema = toTypedSchema(
  yup.object({
    text: yup.string().required(),
  })
)
const { handleSubmit, setFieldValue, setFieldTouched } = useForm({
  validationSchema: schema,
})
const messages = ref<IMessage[]>([])
function getMessages() {
  loading.value = true
  return fetch<{ messages: IMessage[] }>(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`)
    .then((res) => {
      messages.value.splice(0, messages.value.length, ...res?.messages)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `Не удалось получить список сообщений ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      setTimeout(() => {
        scrollToBottom()
      }, 300)
    })
}
const sendMessage = handleSubmit((val: { text: string }) => {
  loading.value = true
  return fetch(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`, {
    method: 'post',
    body: val,
  })
    .then(() => {
      getMessages()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `Не удалось отправить сообщение ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      setFieldValue('text', '')
      setFieldTouched('text', false)
      loading.value = false
    })
})

watch(showSidebar, (val: boolean) => {
  if (val) {
    getMessages()
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
})

function scrollToBottom() {
  if (scrollBar.value) {
    const raw = toRef(scrollBar.value.wrapRef)
    console.log('raw', raw._value.scrollHeight)
    const scrollYValue = raw._value.scrollHeight + messages.value.length
    y.value += scrollYValue
  }
}
</script>

<template>
  <el-button @click="showSidebar = !showSidebar">Открыть чат</el-button>
  <RightSideBar
    width="50%"
    style="overflow-y: auto"
    :title="`Чат по заказу ${invoiceStore.invoice?.id}`"
    v-model="showSidebar"
  >
    <div v-loading="loading" class="h-10" v-if="loading && messages.length === 0"></div>
    <div class="bg-gray p-2" v-if="messages.length > 0" v-loading="loading">
      <el-scrollbar ref="scrollBar" height="660px">
        <div class="p-4 border" style="font-size: 12px; height: 660px">
          <div
            v-for="msg in messages"
            :key="msg.id"
            class="flex mb-1 leading-5"
            :class="msg.author_type === 'Client' ? '' : 'justify-end'"
          >
            <div class="p-2 border w-3/6" :class="msg.author_type === 'Client' ? 'bg-purple-light' : 'bg-green'">
              {{ msg.text }}
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <!--    <el-empty description="В этом чате пока нет сообщений" v-else />-->

    <div class="p-2" v-if="!loading">
      <el-form @submit.prevent="sendMessage" :validation-schema="schema" class="w-full">
        <el-row class="flex" :gutter="6">
          <el-col :lg="14">
            <FormItem name="text" v-slot="{ value, onBlur, onInput }" :label="''">
              <el-input
                :model-value="value"
                @blur="onBlur"
                @input="onInput"
                placeholder="Напишите сообщение"
                type="textarea"
                :rows="4"
                class="p-1"
              />
            </FormItem>
          </el-col>
          <el-col :lg="4">
            <el-button :disabled="loading" :loading="loading" type="primary" native-type="submit">Отправить</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </RightSideBar>
</template>

<style scoped lang="sass"></style>
