<script lang="ts" setup>
interface IProps {
  title?: string
  width?: string
}
defineProps<IProps>()
const model = defineModel<boolean>({ default: false, required: true })
function closeSidebar() {
  model.value = false
}
</script>

<template>
  <transition name="slide-fade">
    <div class="ng_right_sidebar" v-if="model">
      <div :style="width ? { width: width } : { width: '70%' }" @click="closeSidebar"></div>
      <div class="ng_right_sidebar--main sidebar-area" :style="width ? { width: width } : { width: '30%' }">
        <div class="bg-gray padding--20 flex">
          <img
            class="button--text-hover mr-8"
            width="20"
            @click="closeSidebar"
            src="@/assets/close_icon.svg"
            alt="close_icon"
          />
          <h3>{{ title }}</h3>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style lang="sass" scoped>
.ng_right_sidebar
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.33)
  display: flex
  justify-content: flex-end
  z-index: 1000
  &--main
    position: relative
    height: 100%
    background: #fff
    overflow: auto
    padding-bottom: 40px
.ng_right_sidebar--main
  transition: all 0.5s ease-out
.slide-fade-enter-active
  transition: all 0.5s ease-out

.slide-fade-leave-active
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from .ng_right_sidebar--main,
.slide-fade-leave-to .ng_right_sidebar--main
  transform: translateX(50%)
  opacity: 0
@media(max-width: 767px)
  .ng_right_sidebar
    &--main
      position: relative
      width: 100%
      height: 100%
      background: #fff
      overflow: auto
      padding-bottom: 40px
</style>
