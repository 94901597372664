import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center flex-wrap" }
const _hoisted_3 = ["onClick"]

import { computed, ref } from 'vue'
import useFileDownload, { IDownloadFileConfig } from '@/use/useFileDownload'
import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AdminRenderFiles from '@/06-Entities/AdminInvoiceRemote/ui/AdminRenderFiles.vue'
import { useFileAdminRepository } from '@/entityes/invoice/file/file.admin.repository'


export default /*@__PURE__*/_defineComponent({
  __name: 'TBankChatFiles',
  setup(__props) {

const fileStore = useFileStore()
const invoiceStore = useInvoiceStore()
const { downloadFileRequest } = useFileDownload()
const { getAllFilesInInvoice } = useFileAdminRepository()
const invoiceId = computed(() => invoiceStore.invoice && invoiceStore.invoice?.id)
const TBankChatFiles = computed(
  () => fileStore.filesAdmin && fileStore.filesAdmin.files.filter((file) => file.tag === 'tinkoff_chat_files')
)
const tp = ref(1)
const cp = ref(1)
const getFile = (config: IDownloadFileConfig, file: IDownloadFileConfig) => {
  file.isLoading = true
  downloadFileRequest({
    invoiceId: config.invoiceId,
    fileType: config.fileType,
    fileId: config.fileId,
    name: config.name,
  })?.finally(() => {
    file.isLoading = false
  })
}
function changePage(page: number) {
  if (!!invoiceStore.invoice) {
    return getAllFilesInInvoice(invoiceStore.invoice.id, page)
  }
}

function setPagination() {
  tp.value = fileStore.filesAdmin?.total_pages || 1
  cp.value = fileStore.filesAdmin?.current_page || 1
}

setPagination()

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (!!_unref(fileStore).filesAdmin && !!_unref(invoiceStore).invoice)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_pagination, {
          class: "mb-2",
          "hide-on-single-page": tp.value === 1,
          "current-page": cp.value,
          total: tp.value,
          layout: "prev, pager, next",
          "page-size": 1,
          onCurrentChange: changePage,
          background: ""
        }, null, 8, ["hide-on-single-page", "current-page", "total"]),
        _createVNode(_component_el_scrollbar, { "max-height": "400" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(TBankChatFiles.value, (file) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file.id,
                  onClick: ($event: any) => (
            getFile({ invoiceId: _unref(invoiceStore).invoice.id, fileType: file.tag, fileId: file.id, name: file.name }, file)
          ),
                  class: "mb-2 mr-2"
                }, [
                  _createVNode(AdminRenderFiles, { file: file }, null, 8, ["file"])
                ], 8, _hoisted_3))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})