import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-10"
}
const _hoisted_2 = {
  key: 1,
  class: "bg-gray p-2"
}
const _hoisted_3 = {
  class: "p-4 border",
  style: {"font-size":"12px","height":"660px"}
}
const _hoisted_4 = {
  key: 2,
  class: "p-2"
}

import { ref, watch, toRef } from 'vue'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useRequestService } from '@/use/useRequestService'
import { ElNotification } from 'element-plus'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import FormItem from '@/components/supportComponents/FormItem.vue'
import { useScroll } from '@vueuse/core'

interface IMessage {
  author_type: 'Client' | 'Manager'
  created_at: string
  external_id: string
  id: number
  text: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TBankChat',
  setup(__props) {

const { fetch } = useRequestService()
const invoiceStore = useInvoiceStore()
const showSidebar = ref<boolean>(false)
const loading = ref<boolean>(false)
const scrollBar = ref<HTMLElement | null>(null)
const { y } = useScroll(scrollBar, { behavior: 'smooth' })

const schema = toTypedSchema(
  yup.object({
    text: yup.string().required(),
  })
)
const { handleSubmit, setFieldValue, setFieldTouched } = useForm({
  validationSchema: schema,
})
const messages = ref<IMessage[]>([])
function getMessages() {
  loading.value = true
  return fetch<{ messages: IMessage[] }>(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`)
    .then((res) => {
      messages.value.splice(0, messages.value.length, ...res?.messages)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `Не удалось получить список сообщений ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      setTimeout(() => {
        scrollToBottom()
      }, 300)
    })
}
const sendMessage = handleSubmit((val: { text: string }) => {
  loading.value = true
  return fetch(`/admin/invoices/${invoiceStore.invoice?.id}/t_chat`, {
    method: 'post',
    body: val,
  })
    .then(() => {
      getMessages()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `Не удалось отправить сообщение ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      setFieldValue('text', '')
      setFieldTouched('text', false)
      loading.value = false
    })
})

watch(showSidebar, (val: boolean) => {
  if (val) {
    getMessages()
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
})

function scrollToBottom() {
  if (scrollBar.value) {
    const raw = toRef(scrollBar.value.wrapRef)
    console.log('raw', raw._value.scrollHeight)
    const scrollYValue = raw._value.scrollHeight + messages.value.length
    y.value += scrollYValue
  }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showSidebar.value = !showSidebar.value))
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Открыть чат")
      ])),
      _: 1
    }),
    _createVNode(RightSideBar, {
      width: "50%",
      style: {"overflow-y":"auto"},
      title: `Чат по заказу ${_unref(invoiceStore).invoice?.id}`,
      modelValue: showSidebar.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showSidebar).value = $event))
    }, {
      default: _withCtx(() => [
        (loading.value && messages.value.length === 0)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
              [_directive_loading, loading.value]
            ])
          : _createCommentVNode("", true),
        (messages.value.length > 0)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_scrollbar, {
                ref_key: "scrollBar",
                ref: scrollBar,
                height: "660px"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (msg) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: msg.id,
                        class: _normalizeClass(["flex mb-1 leading-5", msg.author_type === 'Client' ? '' : 'justify-end'])
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["p-2 border w-3/6", msg.author_type === 'Client' ? 'bg-purple-light' : 'bg-green'])
                        }, _toDisplayString(msg.text), 3)
                      ], 2))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 512)
            ])), [
              [_directive_loading, loading.value]
            ])
          : _createCommentVNode("", true),
        (!loading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_el_form, {
                onSubmit: _withModifiers(_unref(sendMessage), ["prevent"]),
                "validation-schema": _unref(schema),
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, {
                    class: "flex",
                    gutter: 6
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { lg: 14 }, {
                        default: _withCtx(() => [
                          _createVNode(FormItem, {
                            name: "text",
                            label: ''
                          }, {
                            default: _withCtx(({ value, onBlur, onInput }) => [
                              _createVNode(_component_el_input, {
                                "model-value": value,
                                onBlur: onBlur,
                                onInput: onInput,
                                placeholder: "Напишите сообщение",
                                type: "textarea",
                                rows: 4,
                                class: "p-1"
                              }, null, 8, ["model-value", "onBlur", "onInput"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { lg: 4 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            disabled: loading.value,
                            loading: loading.value,
                            type: "primary",
                            "native-type": "submit"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Отправить")
                            ])),
                            _: 1
                          }, 8, ["disabled", "loading"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit", "validation-schema"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ], 64))
}
}

})