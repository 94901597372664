<script lang="ts" setup>
import { computed } from 'vue'
import { IDeclaration } from '@/entityes/invoice/declaration/declaration.type'
import { ITBankQuestionnaire } from '@/entityes/invoice/TBankQuestionnaire/t_bank_questionnaire.type'

interface IProps {
  declaration: IDeclaration
  questionnaire: ITBankQuestionnaire
}
const props = defineProps<IProps>()
const version = computed(() => props.declaration.correction_number)
const resident = computed(() => props.questionnaire?.is_resident)
const foreignIncomes = computed(() => props?.questionnaire?.income_sources['foreign-investment-incomes']?.chosenOptions)
const hasTinkoffDividends = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('tinkoff-foreign-securities-dividends')
  } else {
    return false
  }
})
const hasForeignBrokerAccounts = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('foreign-broker-account')
  } else {
    return false
  }
})
const hasRussianBrokerAccounts = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('russian-brokers-foreign-securities-dividends')
  } else {
    return false
  }
})

const res = computed(() => {
  return {
    dividends: {
      title: 'Дивиденды Тинькофф',
      show: hasTinkoffDividends.value,
    },
    foreignBrokers: {
      title: 'Зарубежные брокеры',
      show: hasForeignBrokerAccounts.value,
    },
    russianBrokers: {
      title: 'Брокеры РФ',
      show: hasRussianBrokerAccounts.value,
    },
  }
})
</script>

<template>
  <div>
    <p class="mb-2">Версия декларации: {{ declaration.correction_number }}</p>
    <p class="mb-2">Резидент: {{ resident ? 'да' : 'нет' }}</p>
    <div v-for="item in res" :key="item.title" class="mb-2">
      <div v-if="item.show" class="flex items-center">
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
