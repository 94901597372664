import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center flex-wrap" }
const _hoisted_3 = ["onClick"]

import { useFileStore } from '@/entityes/invoice/file/file.store'
import { useFileAdminRepository } from '@/entityes/invoice/file/file.admin.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useFileDownload from '@/use/useFileDownload'
import AdminRenderFiles from './AdminRenderFiles.vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FileInInvoiceTab',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const fileStore = useFileStore()
const fileAdminRepository = useFileAdminRepository()
const { downloadFileRequest } = useFileDownload()
const tp = ref(1)
const cp = ref(1)

function changePage(page: number) {
  if (!invoiceStore.invoice) return
  fileAdminRepository.getAllFilesInInvoice(invoiceStore.invoice.id, page).then((res) => {
    tp.value = res.total_pages
    cp.value = page
  })
}
function init() {
  if (!invoiceStore.invoice) return
  fileAdminRepository.getAllFilesInInvoice(invoiceStore.invoice.id, 1).then((res) => {
    tp.value = res.total_pages
    cp.value = res.current_page
  })
}
init()

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (!!_unref(fileStore).filesAdmin && !!_unref(invoiceStore).invoice)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_pagination, {
          class: "mb-8",
          "hide-on-single-page": tp.value === 1,
          "current-page": cp.value,
          total: tp.value,
          layout: "prev, pager, next",
          "page-size": 1,
          onCurrentChange: changePage,
          background: ""
        }, null, 8, ["hide-on-single-page", "current-page", "total"]),
        _createVNode(_component_el_scrollbar, { "max-height": "400" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fileStore).filesAdmin.files, (file) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file.id,
                  onClick: ($event: any) => (
            _unref(downloadFileRequest)({
              invoiceId: _unref(invoiceStore).invoice.id,
              fileType: file.tag,
              fileId: file.id,
              name: file.name,
            })
          ),
                  class: "mb-4 mr-4"
                }, [
                  _createVNode(AdminRenderFiles, { file: file }, null, 8, ["file"])
                ], 8, _hoisted_3))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})